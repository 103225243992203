import React from "react"
import Box from "@material-ui/core/Box"
import scrollTo from "gatsby-plugin-smoothscroll"

import { makeStyles } from "@material-ui/core/styles"

import { navigation } from '../../helpers/constants'

const useStyles = makeStyles({
  list: {
    margin: 0,
    padding: 0,
    display: "flex",
    listStyle: "none",
  },
  listItem: {
    color: "#2F4858",
    cursor: 'pointer',
  },
})

const Navigation = () => {
  const classes = useStyles()

  return (
    <nav>
      <Box component="ul" className={classes.list}>
        {navigation.map(({ label, slug }) => (
          <Box
            px={0.5}
            mx={1}
            component="li"
            key={label}
            className={classes.listItem}
            onClick={() => scrollTo(slug, "start")}
          >
            {label}
          </Box>
        ))}
      </Box>
    </nav>
  )
}

export default Navigation
