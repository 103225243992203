import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql, Link } from "gatsby"
import { ThemeProvider, createTheme, CssBaseline } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Header from "../header"
import Footer from "../footer"

const theme = createTheme({
  typography: {
    fontSize: 18,
    fontFamily: ["Montserrat", "sans-serif"].join(", "),
    "@media (max-width: 599px)": {
      fontSize: 16,
    },
    body1: {
      fontSize: 18,
      fontFamily: ["Montserrat", "sans-serif"].join(", "),
      "@media (max-width: 599px)": {
        fontSize: 16,
      },
    },
    body2: {
      fontSize: 16,
      fontFamily: ["Montserrat", "sans-serif"].join(", "),
      "@media (max-width: 599px)": {
        fontSize: 14,
      },
    },
    h1: {
      textTransform: "uppercase",
      fontFamily: ["Changa One", "cursive"].join(", "),
    },
    h2: {
      textTransform: "uppercase",
      fontFamily: ["Changa One", "cursive"].join(", "),
    },
    h3: {
      textTransform: "uppercase",
      fontFamily: ["Changa One", "cursive"].join(", "),
    },
    h4: {
      textTransform: "uppercase",
      fontFamily: ["Changa One", "cursive"].join(", "),
    },
    h5: {
      textTransform: "uppercase",
      fontFamily: ["Changa One", "cursive"].join(", "),
    },
    h6: {
      textTransform: "uppercase",
      fontFamily: ["Changa One", "cursive"].join(", "),
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          color: "inherit",
          "&:hover": {
            color: "inherit",
          },
          "&:hover:active": {
            color: "inherit",
          },
        },
      },
    },
    MuiBackdrop: {
      // root: {
      //   backgroundColor: "rgba(0, 0, 0, 0.7)",
      // },
    },
  },
  palette: {
    error: {
      main: "#FFB085",
    },
    success: {
      main: "#90AACB",
    },
    info: {
      main: "#FEF1E6",
    },
    warning: {
      main: "#F9D5A7",
    },
    primary: {
      main: "#FFB085",
    },
    secondary: {
      main: "#90AACB",
    },
    text: {
      primary: "#2F4858", //"rgba(0, 0, 0, 0.87)",
      secondary: "#fff", // "rgba(0, 0, 0, 0.54)",
      // hint: "rgba(0, 0, 0, 0.38)",
      // disabled: "rgba(0, 0, 0, 0.38)",
    },
  },
})

const useStyles = makeStyles(theme => ({
  main: {},
}))

const Layout = ({ children }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <main className={classes.main}>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
