import React, { useState } from "react"

import AppBar from "../appBar"
import Drawer from "../drawer"

const Header = props => {
  const [drawer, setDrawer] = useState(false)
  return (
    <>
      <AppBar {...props} drawer={drawer} setDrawer={setDrawer} />
      <Drawer {...props} drawer={drawer} setDrawer={setDrawer} />
    </>
  )
}

export default Header
