import React from "react"
import MuiAppBar from "@material-ui/core/AppBar"
import MuiToolbar from "@material-ui/core/Toolbar"
import Hidden from "@material-ui/core/Hidden"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import CssBaseline from "@material-ui/core/CssBaseline"

import { Link } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"

import Logo from '../logo'
import Container from '../container'
import Navigation from "../navigation"

const Toolbar = withStyles({
  gutters: {
    padding: 0,
  },
})(MuiToolbar)

const AppBar = withStyles({
  colorPrimary: {
    backgroundColor: "#FEF1E6",
  },
})(MuiAppBar)

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    display: "flex"
  }
})

const ComponentAppBar = (props) => {
  const classes = useStyles()
  const handleClick = () => {
    props.setDrawer(drawer => !drawer)
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" id="home">
        <Toolbar>
          <Container className={classes.container}>
            <Link to="/" className={classes.logo}>
              <IconButton size="small" aria-label="logo">
                <Logo />
              </IconButton>
            </Link>
            <Hidden smDown>
              <Navigation />
            </Hidden>
            <Hidden mdUp>
              <IconButton
                edge="start"
                size="small"
                onClick={handleClick}
                aria-label="menu"
              >
                <MenuIcon aria-label="menu" className={classes.menuButton} />
              </IconButton>
            </Hidden>
          </Container>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default ComponentAppBar
