import React from "react"
import Box from "@material-ui/core/Box"
import scrollTo from "gatsby-plugin-smoothscroll"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import FacebookIcon from '@material-ui/icons/Facebook';
import MuiSwipeableDrawer from "@material-ui/core/SwipeableDrawer"

import { navigate } from "gatsby-link"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"

import { navigation } from "../../helpers/constants"

const SwipeableDrawer = withStyles({
  paper: {
    color: "#fff",
    padding: 100,
    backgroundColor: "#2F4858",
  },
})(MuiSwipeableDrawer)

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0,
    margin: 0,
    listStyle: "none"
  },
  listItem: {
    cursor: "pointer"
  },
  link: {
    color: theme.palette.common.white,
    display: "block",
    padding: "12px 0",
    textDecoration: "none",
    "& span": {
      fontWeight: "600",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  closeIcon: {
    top: 30,
    color: theme.palette.common.white,
    width: 60,
    right: 30,
    height: 60,
    position: "absolute",
  },
  social: {
    marginTop: theme.spacing(2),
    cursor: "pointer",
    color: theme.palette.common.white
  }
}))

const TemporaryDrawer = props => {
  const classes = useStyles()
  
  const { datoCmsHomePage: { contactFacebook } } = useStaticQuery(graphql`
    query DrawerQuery {
      datoCmsHomePage {
        contactFacebook
      }
    }
  `)

  const handleClick = anchor => {
    props.setDrawer(false)
    setTimeout(() => {
      scrollTo(anchor, "start")
    }, 500)
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={props.drawer}
      onOpen={() => props.setDrawer(true)}
      onClose={() => props.setDrawer(false)}
    >
      <>
        <IconButton
          aria-label="close"
          onClick={() => props.setDrawer(false)}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Box component="ul" className={classes.list}>
          {navigation.map(({ label, slug }) => (
            <Typography
              component="li"
              variant="h6"
              key={label}
              className={classes.listItem}
              onClick={() => handleClick(slug)}
            >
              {label}
            </Typography>
          ))}
        </Box>
        {contactFacebook && (
          <FacebookIcon className={classes.social} onClick={() => navigate(contactFacebook)} />
        )}
      </>
    </SwipeableDrawer>
  )
}

export default TemporaryDrawer
