import React from 'react'

import { Link } from 'gatsby'
import { makeStyles } from "@material-ui/core/styles"

import Container from '../container'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.5)}px 0`,
    fontSize: "0.9rem",
    backgroundColor: "#2F4859",
  },
  link: {
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
    }
  }
}))
  
const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <Container>
        © {new Date().getFullYear()} <Link to="/" className={classes.link}>SOS Kot i Pies</Link> | Wszelkie prawa zastrzeżone
      </Container>
    </footer>
  )
}



export default Footer
