export const navigation = [
  {
    label: "O nas",
    slug: "#about",
  },
  {
    label: "Referencje",
    slug: "#reference",
  },
  {
    label: "Cennik",
    slug: "#price",
  },
  {
    label: "Promocje",
    slug: "#promo",
  },
  {
    label: "Kontakt",
    slug: "#contact",
  },
]

export const INITIAL_REF_LIMIT = 3